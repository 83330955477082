import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="container-fluid mt-5">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gridGap: "30px",
        }}
      >
        <img
          style={{ maxWidth: "400px" }}
          src={`${process.env.PUBLIC_URL + "/image_react/error-bg.png"}`}
          alt="Not Found"
        />
        <h2>The Page you are looking for doesn't exit !</h2>
        <Link to="/" className="btn btn-primary">
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
