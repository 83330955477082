import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { SERVER_URL } from "../constants";

const ConfirmDialog = ({ open = false, onConfirm, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{
          p: 6,
        }}
      >
        <Box
          sx={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            "& svg": { mb: 6, color: "warning.main" },
          }}
        >
          <Typography
            sx={{
              color: "common.black",
            }}
          >
            Are you sure you would like to delete your account?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
        }}
      >
        <Button variant="contained" sx={{ mr: 2 }} onClick={onConfirm}>
          Yes
        </Button>
        <Button
          variant="outlined"
          sx={{
            color: "#B2B4B8",
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteAccount = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleConfirm = async () => {
    setOpen(false);

    fetch(`${SERVER_URL}/v1/auth/delete-account`, {
      method: "POST",
      body: JSON.stringify({
        email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        toast.success(res.data.message);

        setEmail("");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#f2f2f2",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "black",
      }}
    >
      <div
        style={{
          marginTop: "50px",
          color: "black",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2 style={{ textAlign: "center" }}>Delete Account</h2>
        <Typography
          sx={{
            textAlign: "center",
            color: "gray",
          }}
        >
          NOTE: You need verified email to delete your account
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ol>
            <li>
              <Typography
                sx={{
                  color: "gray",
                }}
              >
                Enter your email address and submit.
              </Typography>
            </li>
            <li>
              <Typography
                sx={{
                  color: "gray",
                }}
              >
                You will receive an email with further instructions if your
                email is verified.
              </Typography>
            </li>
          </ol>
        </div>
        <p style={{ textAlign: "center", color: "gray" }}>
          Please enter your email to delete your account.
        </p>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <label htmlFor="mobileNumber">Email:</label>
            <input
              type="email"
              id="mobileNumber"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
          <button
            style={{ marginTop: "10px", textAlign: "center" }}
            type="submit"
          >
            Delete Account
          </button>
        </form>
      </div>
      <ConfirmDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default DeleteAccount;
