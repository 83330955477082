import React, { useEffect, useRef, useState } from "react";
import { useLocation, NavLink as Link } from "react-router-dom";
// import DropDown from "./DropDown";

import "../css/navbar.css";
const Navbar = () => {
  let location = useLocation();
  const ref = useRef();

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <header
      ref={ref}
      className={`header-section ${
        offset > (ref?.current?.clientHeight ?? 2) / 2
          ? "animated fadeInDown header-fixed"
          : ""
      } `}
    >
      <nav className="navbar navbar-expand-lg  ">
        <div className="container-fluid">
          <div className="container" style={{ maxWidth: "fit-content" }}>
            <Link className="navbar-brand " to="/">
              <img
                src="../image_react/logot1.png"
                alt="logo"
                style={{
                  marginTop: "0px",
                }}
                height="40px"
              />
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
          ></div>
          <div className="nav">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item mx-2">
                <Link
                  className={`nav-link ${
                    location.pathname === "/" ? "active-self" : ""
                  }`}
                  to="/"
                  activeStyle={{
                    color: "#fff",
                  }}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link
                  className={`nav-link ${
                    location.pathname === "/about" ? "active-self" : ""
                  }`}
                  to="/about"
                >
                  About
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link
                  className={`nav-link ${
                    location.pathname === "/contact" ? "active-self" : ""
                  }`}
                  to="/contact"
                >
                  Contact
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link
                  className={`nav-link ${
                    location.pathname === "/privacy-policy" ? "active-self" : ""
                  }`}
                  to="/privacy-policy"
                >
                  Privacy & Policy
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link
                  className={`nav-link ${
                    location.pathname === "/terms-conditions"
                      ? "active-self"
                      : ""
                  }`}
                  to="/terms-conditions"
                >
                  Terms & Conditions
                </Link>
              </li>

              {/* <DropDown
                name={"Tipster"}
                dropDownList={[
                  { name: "Become Tipster", to: "/" },
                  { name: "Find Tipster", to: "/" },
                  { name: "Tipster Profile", to: "/" },
                ]}
              />
              <DropDown
                name={"All Tips"}
                dropDownList={[
                  { name: "All Tips", to: "/" },
                  { name: "Match Betting", to: "/" },
                ]}
              />
              <DropDown
                name={"Support"}
                dropDownList={[
                  { name: "Support Help", to: "/" },
                  { name: "Buyers Faq", to: "/" },
                  { name: "Tipster Faq", to: "/" },
                  { name: "Faq Details", to: "/" },
                ]}
              />
              <DropDown
                name={"Pages"}
                dropDownList={[
                  { name: "About Us", to: "/" },
                  { name: "Affiliate", to: "/" },
                  { name: "Post Tips", to: "/" },
                  { name: "User Dashboard", to: "/" },
                  { name: "Privacy Policy", to: "/" },
                  { name: "Terms Conditions", to: "/" },
                  { name: "Create Profile", to: "/" },
                  { name: "Error", to: "/" },
                ]}
              /> */}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
