import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../constants";
import "../css/home.css";
import { Avatar } from "@mui/material";
import Match, {
  FantasticPlayersView,
  PitchReportView,
  MostSelectedCapAndViceCap,
  MostSelected,
} from "./Match";
import Footer from "./Footer";

const whatsBetterSection = [
  {
    title: "Powerplay bowler and batter analyst",
    subTitles: {
      t1: "Find your match winners by viewing detailed information of the top players for Powerplay bowler and batter",
      t2: "",
    },
    imageUrl: "../image_react/icons8-wicket-66.png",
  },
  {
    title: "Death Over bowler analyst",
    subTitles: {
      t1: "Find your match winners by viewing detailed information of the top players for Powerplay bowler and batter",
      t2: "",
    },
    imageUrl: "../image_react/icons8-cricket-64.png",
  },
  {
    title: "Lots of Reward",
    subTitles: {
      t1: "Start bt Daily Check-in Bonus, TEAM PREDICTION, Best predictor for most like, most view and points",
      t2: "",
    },
    imageUrl: "../image_react/icons8-coins-100.png",
  },
  {
    title: "Pitch Reports",
    subTitles: {
      t1: "Understand the pitch performance to select the players as per the pitch conditions",
      t2: "",
    },
    imageUrl: "../image_react/icons8-report-file-64.png",
  },
  {
    title: "Player Performance",
    subTitles: {
      t1: "Shows the top performing players to help you pick the best",
      t2: "",
    },
    imageUrl: "../image_react/icons8-personal-growth-80.png",
  },
  {
    title: "H2H Player Compare",
    subTitles: {
      t1: "Study key player battles across various start and metrics to select the finest player",
      t2: "",
    },
    imageUrl: "../image_react/icons8-comparing-100.png",
  },
];

const questions = [
  {
    question: "How can I earn coins?",
    answer:
      "You can win points/coins for creating teams and If user got first rank in Most Viewed, Most Liked and Most points earned in specific match.",
  },
  {
    question: "How can I do prediction?",
    answer:
      "You can do predictions for upcoming matches, and create your team from My Teams section.",
  },
  {
    question: "Which kind of analytics are you provide?",
    answer:
      "We provide Pitch reports, bowler/batsman performances in power plays, death over bowler specialist, team/players & head to head comparisons,  best picks by predictors.",
  },
  {
    question: "How I become trending predictor?",
    answer:
      "We are declaring trending predictor based on last continuous five (5) matches performance.",
  },
  {
    question: "How can I convert coins into real cash?",
    answer: "For convert coins into real cash, you require minimum 5k coins.",
  },
  {
    question: "How can I withdraw real cash?",
    answer:
      "For withdrawal, you require minimum 100 rupee also you have to verify documents (PAN, Bank details).",
  },
];

const Home = () => {
  const trendingPredictors = useQuery("trendingPredictors", () =>
    fetch(`${SERVER_URL}/v1/userTeams/getTrendingPredictors`).then((res) =>
      res.json()
    )
  );
  const statistics = useQuery("statistics", () =>
    fetch(`${SERVER_URL}/v1/getDashboardData/statistics`).then((res) =>
      res.json()
    )
  );

  const matches = useQuery("matches", () =>
    fetch(
      `${SERVER_URL}/v1/matches/getMatches?matchStatus=UPCOMING&pageNumber=1`
    ).then((res) => res.json())
  );

  return (
    <div className="home">
      <div className="Home">
        <div className="light">
          <img src="../image_react/light-effect.png" alt="" />
        </div>
        <div className="lightE">
          <img src="../image_react/light-effect.png" alt="" />
        </div>
        <div className="first">Unlock your winning opportunities</div>
        <div className="second">We analyze the data, You will win</div>
        <div className="third">
          You will find the Cricket prediction data analysis here
        </div>
        <a
          className="button"
          href="#top_predictors"
          style={{ textDecoration: "none" }}
        >
          Find Predictors
        </a>
        <img src="../image_react/banner-obj-1.png" className="banner1" alt="" />
        <img src="../image_react/banner-obj-2.png" className="banner2" alt="" />
        <img src="../image_react/banner-obj-3.png" className="banner4" alt="" />

        {/* <img src="../image_react/banner3.png" className="banner3" alt="" /> */}
      </div>
      <div className="i1">
        <div className="b1">Number Said More Than Words</div>
        <br />
        <div className="b2">The Numbers Don't Lie</div>
        <br />
      </div>
      <div className="icon">
        <div>
          <img
            src="../image_react/icons8-cricket-miscellaneous/icons8-cricket-100.png"
            className="icon1"
            alt=""
            style={{ borderRadius: "50%" }}
            width="100px"
            height="100px"
          />
          <div className="cou">
            <h4 className="h4">176</h4>
            <p>Average Team</p>
          </div>
        </div>
        <div>
          <img
            src="../image_react/icons8-polyline-material-outlined/icons8-polyline-96.png"
            className="icon2"
            alt=""
            style={{ borderRadius: "50%" }}
            width="100px"
            height="100px"
          />
          <div className="cou">
            <h4 className="h4">785 Pts</h4>

            <p>Average Point</p>
          </div>
        </div>
        <div>
          <img
            src="../image_react/icons8-prediction-64.png"
            className="icon4"
            alt=""
            width="100px"
            height="100px"
            style={{ borderRadius: "50%" }}
          />
          <div className="cou">
            <h4 className="h4">160</h4>
            <p>Predictor</p>
          </div>
        </div>
      </div>
      <div className="top-bg" id="top_predictors">
        <div style={{ width: "100%" }}>
          <p className="secondLine">Top Predictors</p>
          <br />
          <div className="thirdLine">
            Place where World's best predictors and data research for upcoming
            cricket matches
          </div>
        </div>
        <div className="thirdSection">
          <div className="trending_predictors_parent">
            {trendingPredictors.isSuccess &&
              trendingPredictors?.data?.data?.trendingPredictors?.map(
                (item, index) => {
                  if (index > 5) {
                    return null;
                  }
                  return (
                    <div className="c1" key={item.userId}>
                      <div className="predictors_card_top">
                        <img
                          src="../image2_react/betting-image-6.png"
                          className="top-bg-1"
                          alt=""
                        />
                        <Avatar
                          src={item.displayPicture}
                          className="predictors_avatar"
                        />
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">
                          {item.firstName + " " + item.lastName}
                        </h5>
                        <div className="card-stats">
                          <div className="card-stat">
                            <p style={{ color: "#ffd133" }}>{index + 1}</p>
                            <p>Rank</p>
                          </div>
                          <div className="card-stat">
                            <p style={{ color: "#ffd133" }}>
                              {item.totalPoints}
                            </p>
                            <p>Points</p>
                          </div>
                        </div>
                        <a href="#download_app_parent" className="m btn ">
                          View Profile
                        </a>
                      </div>
                    </div>
                  );
                }
              )}
          </div>
        </div>
      </div>
      <div className="fl">
        <div className="fl">
          {/* <div className="fl1">
          You can find the Top Tipsters sorting them by Profit,Yield or Hit
          Rate.
        </div> */}
          <div className="fl2">3 Easy steps to find the best analysts</div>
          <div className="fl3">
            Make multiple combinations to find the Best prediction and get
            inspired from their predictions.
          </div>
          <div className="fl4">
            Winning fantasy sports platform in Three Simple Steps.
          </div>
          {/* <div className="fl5">
          thrilling victories in only three short steps.
        </div> */}
        </div>

        <div className="container">
          <div className="forthSection">
            <div style={{ position: "relative" }}>
              <img
                src="../image_react/match_select.png"
                className="how1"
                style={{ width: "400px" }}
                alt=""
              />
              <div className="ro1">
                <p>01</p>
              </div>
            </div>
            <div className="how-1">
              <div className="h1">Select Match</div>
              <div className="h2">
                We make it very easy for you to find the data analysis and
                predictor
              </div>
              <div className="h3">
                with 99% accuracy statistics we provide, you can choose best
              </div>
              <div className="h5">player 30 second.</div>
            </div>
          </div>
          <div className="one">
            <img src="../image2_react/arrow-one.png" className="one" alt="" />
          </div>
          <div className="fifthSection">
            <div className="how-2">
              <div className="h1">Fantasy analysis</div>
              <div className="h2">
                Find your match winners by viewing detailed information of every
                player
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <img
                src="../image_react/half.png"
                className="how2"
                style={{ width: "400px" }}
                alt=""
              />
              <div className="ro2">
                <p>02</p>
              </div>
            </div>
          </div>
          <div className="two">
            <img src="../image2_react/arrow-two.png" className="two" alt="" />
          </div>
          <div className="sixthSection">
            <div style={{ position: "relative" }}>
              <img
                src="../image_react/myteam.png"
                style={{ width: "444px" }}
                className="how3"
                alt=""
              />
              <div className="ro3">03</div>
            </div>
            <div className="how-3">
              <div className="h1">Create your team </div>
              <div className="h2">Start creating your winning teams!</div>
              {/* <div className="h3">your winnings grow!</div> */}
            </div>
          </div>
        </div>
        <div className="seventhSection">
          <div className="s" id="download_app_parent">
            <div className="line1">MyPredict11 is waiting for you</div>
            <div className="line2">We Want You to Win. It's As</div>
            <div className="line2">Simple As That</div>
            <div className="line3">MyPredict11 has got everything</div>
            <div className="line4">to help you create better fantasy teams</div>
          </div>
        </div>
        <div className="box" id="download_app">
          <div className="join">Download our app</div>
          <div className="download">
            <a
              href="https://apps.apple.com/in/app/mypredict11/id6443919564"
              className="download_logo"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{
                  objectFit: "contain",
                  borderRadius: "10px",
                  padding: "5px 10px",
                  backgroundColor: "white",
                }}
                src="../image_react/app_store_logo.png"
                height="65px"
                alt=""
              />
              {/* <div>
                <p>Download on the</p>
                <p>App Store</p>
              </div> */}
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.ihill.mypredict11"
              rel="noreferrer"
            >
              <img
                src="../image_react/play_store_logo.png"
                height="65px"
                alt=""
                style={{
                  objectFit: "contain",
                  borderRadius: "10px",
                  padding: "5px 10px",
                  backgroundColor: "white",
                }}
              ></img>
            </a>
          </div>
        </div>
        <div className="eighthSection">
          <div className="upcoming_matches_header">
            <p className="e3">Upcoming Matches</p>
            <p className="e5">The fastest growing prediction community</p>
          </div>
          <div className="match_container">
            {matches.isSuccess &&
              matches.data?.data?.matches?.map((match, index) => {
                return (
                  <div key={index} className="match">
                    <div className="match_header">
                      <p style={{ color: "white", margin: 0 }}>
                        {match.matchStartDateTime}
                      </p>
                      <div
                        style={{
                          border: "1px solid #ffd113",
                          padding: "5px 10px",
                          borderRadius: "12px",
                          color: "white",
                        }}
                      >
                        &nbsp; {match.totalPredictors} Predictions
                      </div>
                    </div>
                    <div className="match_teams">
                      <div>
                        <Avatar
                          style={{ marginRight: "5px" }}
                          src={match.team1FlagURL}
                        />
                        {match.team1DisplayName}
                      </div>
                      <p style={{ color: "#ffd133" }}>VS</p>
                      <div>
                        {match.team2DisplayName}
                        <Avatar
                          src={match.team2FlagURL}
                          style={{ marginLeft: "5px" }}
                        />
                      </div>
                    </div>
                    <p className="match_series">{match.seriesDname}</p>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="nineSection">
          <div className="nineHead">
            <div className="nineL1">Why MyPredict11 is better?</div>
            <div className="nineL2">This is What You Get</div>
            <div className="nineL3">
              Mypredict11 analyses 100% of data points to provide you with
              in-depth stats and
            </div>
            <div className="nineL4">
              predicted lineups to help you ace your fantasy game.
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gridGap: "20px",
                // flex: "0.5",
              }}
            >
              {whatsBetterSection.map(
                ({ imageUrl, title, subTitles: { t1, t2 } }, index) => {
                  return (
                    <div key={index} className="nineSec">
                      <div style={{ flex: "0.2" }}>
                        <img
                          src={imageUrl}
                          style={{ width: "85px", height: "95px" }}
                          alt=""
                        />
                      </div>
                      <div style={{ flex: "0.8" }}>
                        <p className="nineSecL1">{title}</p>
                        <p className="nineSecL2">{t1}</p>
                        <p className="nineSecL3">{t2}</p>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
            <div
              style={
                {
                  // flex: ".5",
                }
              }
            >
              <img
                src="../image_react/side_s.png"
                style={{
                  maxWidth: "500px",
                  textAlign: "center",
                }}
                alt=""
              />
            </div>
            {/* <div className="nineSec">
              <div>
                <img
                  src="../image2_react/10194549.png"
                  className="why1"
                  style={{ width: "85px", height: "95px" }}
                  alt=""
                />
              </div>
              <div>
                <p className="nineSecL1">Powerplay bowler and batter analyst</p>
                <p className="nineSecL2">
                  Find your match winners by viewing detailed information of the
                  top
                </p>
                <p className="nineSecL3">
                  players for Powerplay bowler and batter
                </p>
              </div>
            </div> */}
          </div>
          <div className="tenSection">
            <div className="tenSec">
              <div className="tenL1">Find the best trending players</div>
              <div className="tenL2">The Best trending players</div>
              <div className="tenL3">
                Get algorithm suggested player analysis
              </div>
            </div>
          </div>
          <div className="winningSection">
            {statistics.isSuccess &&
              statistics?.data?.data?.matches?.map((match, index) => {
                return (
                  <div key={index} className="trending_players_match">
                    {match?.fantasyPoints?.length > 0 && (
                      <div className="trending_players_match_fantasy">
                        <Match
                          match={match}
                          heading="Most fantastic Top 3 Points"
                          View={FantasticPlayersView}
                        />
                      </div>
                    )}
                    {match?.pitchReport && (
                      <div className="trending_players_match_pitch_report">
                        <Match
                          match={match}
                          heading="Pitch Report"
                          View={PitchReportView}
                        />
                      </div>
                    )}
                    {match?.mostSelected?.length && (
                      <div className="trending_players_match_captain_viceCaptain">
                        <Match
                          match={match}
                          heading="Most Selected Top 3 Players"
                          View={MostSelected}
                        />
                      </div>
                    )}
                    {match?.mostSelectedCaptainAndViceCaptain?.captain &&
                      match?.mostSelectedCaptainAndViceCaptain?.viceCaptain && (
                        <div className="trending_players_match_most_selected">
                          <Match
                            match={match}
                            heading="Most Selected Captain & Vice Captain"
                            View={MostSelectedCapAndViceCap}
                          />
                        </div>
                      )}
                  </div>
                );
              })}

            {/* <div className="winningFifth">
              <div className="rank">
                <div className="rank-t">Rank</div>
                <div className="rank-2">5</div>
              </div>
              <div className="review-profile1">
                <img
                  src="../image2_react/latest-tips-2.png"
                  alt=""
                  className="latest"
                />
                <img
                  src="../image2_react/gold-tipsters.png"
                  alt=""
                  className="goldProfile"
                />
                <div className="profileName">Harry Miles</div>
                <div className="review1">
                  <img src="../image2_react/star1.png" className="star" alt="" />
                  <img src="../image2_react/star1.png" className="star" alt="" />
                  <img src="../image2_react/star1.png" className="star" alt="" />
                  <img src="../image2_react/star1.png" className="star" alt="" />
                  <img src="../image2_react/star1.png" className="star" alt="" />
                </div>
              </div>
              <div className="ProfileWinningRate">67% Winning Rate</div>
              <div className="ProfileWinning">
                <div className="winningRate">+5,285.5</div>
                <div className="profit">Profit</div>
              </div>
              <div className="ProfileWinning2">
                <div className="winningRate">7025</div>
                <div className="profit">Tips</div>
              </div>
              <div className="ProfileWinning3">
                <div className="winningRate">3.93%</div>
                <div className="profit">ROI</div>
              </div>
              <div className="ProfileWinning4">
                <div className="winningRate">98.57</div>
                <div className="profit">Avg.odds</div>
              </div>
              <div className="ProfileWinning5">
                <div className="winningRate">98.57</div>
                <div className="profit">Win Rate</div>
              </div>
              <div className="tenRight">
                <div className="tenRight1">$ 25.00</div>
                <div className="tenRight2">Per Month</div>
                <button className="tenRight3 btn">Subscribe</button>
              </div>
            </div> */}
          </div>
        </div>
        <div className="elevenSection">
          <div className="elevenFirstContact">
            <div className="elevenLine1">
              We've put together a list of useful information
            </div>
            <div className="elevenLine2">Frequently asked questions</div>
            <div className="elevenLine3">
              We keep updating our FAQ section. if you have any additional
              question, please{" "}
              <Link to="/contact" className="elevenLine4">
                contact support.
              </Link>
            </div>
          </div>
        </div>
        <div className="elevenSecondContact">
          {questions.map(({ question, answer }, index) => {
            return (
              <FaqQuestion key={index} question={question} answer={answer} />
            );
          })}
        </div>

        <Footer />
      </div>
    </div>
  );
};

const FaqQuestion = ({ answer, question }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef(null);
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    setHeight(ref?.current?.scrollHeight ?? 0);
  }, []);

  return (
    <div className="faq_question_container">
      <div className="faq_question">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="faq_question_icon"></div>
          <p style={{ marginLeft: "8px" }} className="faq_question_text">
            {question}
          </p>
        </div>
        <div style={{ width: "30px", height: "30px" }}>
          <button
            className="plus"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          ></button>
        </div>
      </div>
      <div
        ref={ref}
        className="faq_answer"
        style={{
          maxHeight: isOpen ? height * 2 + "px" : "0px",
        }}
      >
        <hr />
        <p>{answer}</p>
      </div>
    </div>
  );
};

export default Home;
