import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="lastFooter">
      <div className="footer">
        <img
          src="../image_react/logot1.png"
          alt="logo"
          style={{
            marginTop: "0px",
          }}
          height="40px"
        />
        <ul className="footer-line">
          <li>
            <Link className="footer-link" to="/">
              Home
            </Link>
          </li>
          <li className="footer-link">|</li>
          <li>
            <Link className="footer-link" to="/about">
              About
            </Link>
          </li>
          <li className="footer-link">|</li>
          <li>
            <Link className="footer-link" to="/contact">
              Contact
            </Link>
          </li>
          <li className="footer-link">|</li>
          <li>
            <Link className="footer-link" to="/terms-conditions">
              Terms & Conditions
            </Link>
          </li>
          <li className="footer-link">|</li>
          <li>
            <Link className="footer-link" to="/privacy-policy">
              Privacy & Policy
            </Link>
          </li>
        </ul>
      </div>
      <div className="copyright-area">
        <div className="copyright">
          Copyright @ <span className="Copy"> MyPredict11</span>
        </div>
      </div>
      <div className="social-link"></div>
    </div>
  );
};

export default Footer;
