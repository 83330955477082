import React from "react";
import "../css/about.css";
import Footer from "./Footer";

const About = () => {
  return (
    <>
      <div style={{ backgroundColor: "#2b0d88" }}>
        <div className="container">
          <div className="heading">About Us</div>
        </div>
      </div>
      <div className="container">
        <div className="secondSection">
          <div className="terms">
            <p>
              MyPredict11 is the extra ordinary analytics tool for cricket, we
              are providing analysis for ground and players statistical data
              based on past performances.
            </p>
            <p>
              MyPredict11 is absolutely free platform to build World Beating
              Fantasy Cricket Teams.
            </p>
            <p>
              We are providing detailed statistical data to the user for choose
              best 11 players and earn points in MyPredict11 and also help to
              earn money from fantasy cricket platforms. We are providing bit of
              information to make winning teams for fantasy sports.
            </p>
            <p>
              Currently MyPredict11 is focusing on cricket contests to provide
              easiest, fastest and smartest way to make wining teams for fantasy
              cricket platforms.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
