import React from "react";
import "../css/terms.css";
import Footer from "./Footer";

// saneak first_section
// camel case firstSection
// pascal FirstSection

function TermsConditions() {
  return (
    <>
      <div>
        <div style={{ backgroundColor: "#2b0d88" }}>
          <div className="container">
            <div className="heading">Terms Conditions</div>
          </div>
        </div>
        <div className="container">
          <div className="secondSection">
            <div className="terms">
              <p>
                TERMS OF USE Welcome to MyPredict11. The following terms of
                service (the "TOS") apply to your use of these application,
                including access to the Services (as defined below) and Content
                (as defined below) available through the application. Please
                read these terms and conditions carefully before using the
                application, accessing the Content and/or using the Services.
              </p>
              <p>
                BY USING THE APPLICATION, THE SERVICES, OR THE CONTENT, YOU ARE
                CONSENTING TO THE TERMS AND CONDITIONS IN THIS TERMS OF SERVICE
                AGREEMENT AS THEY APPLY TO YOU AND YOUR USAGE AND ACCESS.
              </p>
              <p>
                Your use of the App, the Services, and the Content is also
                subject to all applicable laws and regulations. If you do not
                agree to any of the terms of service in this agreement, you
                should not use the App, the Services, or the Content. Your use
                of the App, the Content, and the Services is also governed by
                our Privacy Policy.
              </p>
              <p>
                <span className="terms__service"> 1.TERMS OF SERVICE</span>{" "}
                MyPredict11 provides the App, the Content, and the Services to
                you subject to the following Terms of Service ("TOS"). The TOS
                may be updated by us from time to time without notifying you. We
                suggest that from time to time you review the TOS for possible
                changes. In addition, when using any Services or accessing any
                Content, you will be subject to any posted guidelines or rules
                applicable to such Services or Content. All such additional
                guidelines or rules are hereby incorporated by reference into
                the TOS.
              </p>
              <p>
                <span className="terms__service">
                  {" "}
                  2. SERVICES AND CONTENT{" "}
                </span>{" "}
                MyPredict11 currently provides users with various features and
                services, including statistical review, analysis, and other
                interactive and non-interactive features, all of which may be
                updated, deleted, or otherwise modified from time to time at the
                discretion of MyPredict11 application. In addition, MyPredict11
                provides access to certain photographs, text, images,
                statistics, logos, and other media and intellectual property
                related to or otherwise associated with MyPredict11. Unless
                stated otherwise, the App, the Services, and the Content are
                subject to the TOS. You understand and agree that the App, the
                Services, and the Content are provided "AS IS" and that
                MyPredict11 assumes no responsibility in connection with your
                use of the App, the Services, or the Content. The App, the
                Services, and the Content are provided for your non-commercial
                entertainment and enjoyment. Please confirm the cricket sports
                analysis tools regulations in your jurisdiction as they vary
                from state to state, province to province and country to
                country. Use of this information in contravention of any law is
                prohibited. Under the TOS, you may download certain Content and
                Services available on the Website to a single personal computing
                device for your use and entertainment. However, you may not
                distribute, modify, republish, or publicly display any of the
                Content or Services unless you have the prior written permission
                of MyPredict11, which permission may be withheld at
                MyPredict11’s sole discretion.
              </p>
              <p>
                <span className="terms__service">
                  {" "}
                  3. DISCLAIMER OF WARRANTIES{" "}
                </span>
                You expressly understand and agree that:
              </p>
              <p>
                <span className="condition">A{")"}.</span> Your use of the
                application, the services and the content is at your sole risk.
                the application, the services and the content are provided on an
                " is" and " available" basis. MyPredict11 expressly disclaims
                all warranties of any kind, whether express or implied,
                including, but not limited to the implied warranties of
                merchantability, fitness for a particular purpose and
                non-infringement.
              </p>
              <p>
                <span className="condition">B{")"}.</span> MyPredict11 make no
                warranty that (i) the application, services or the content will
                meet your requirements, (ii) the application, the services or
                the content will be uninterrupted, timely, secure, or
                error-free, (iii) the results that may be obtained from your use
                of the application, the services or the content will be accurate
                or reliable, (iv) the quality of any products, services,
                information, or other material purchased or obtained by you
                through the application, the services or the content will meet
                your expectations, and (v) any errors in the software operating
                on the application will be corrected.
              </p>
              <p>
                <span className="condition">C{")"}.</span>Any material
                downloaded or otherwise obtained through the use of the website
                or the services is done at your own discretion and risk and that
                you will be solely responsible for any damage to your computer
                system or loss of data that results from the download of any
                such material.
              </p>
              <p>
                <span className="condition">D{")"}.</span> No advice or
                information, whether oral or written, obtained by you from the
                website or through or from the services or the content shall
                create any warranty not expressly stated in the TOS.
              </p>
              <p>
                <span className="terms__service">
                  {" "}
                  4. LIMITATION OF LIABILITY{" "}
                </span>{" "}
                You expressly understand and agree that MyPredict11 shall not be
                liable for any direct, indirect, incidental, special,
                consequential or exemplary damages, including but not limited to
                damages for loss of profits, goodwill, use, data or other
                intangible losses (even if MyPredict11 has been advised of the
                possibility of such damages), resulting from: (i) the use or the
                inability to use the application, the services, or the content;
                (ii) the cost of procurement of substitute goods and services
                resulting from any goods, data, information or services
                purchased or obtained or messages received or transactions
                entered into through or from the application, the services, or
                the content; (iii) unauthorized access to or alteration of your
                transmissions or data; (iv) statements or conduct of any third
                party on the application, the services, or in the content; or
                (v) any other matter relating to the application, the services,
                or the content.
              </p>
              <p>
                <span className="terms__service">
                  {" "}
                  5. EXCLUSIONS AND LIMITATIONS{" "}
                </span>{" "}
                Some jurisdictions do not allow the exclusion of certain
                warranties or the limitation or exclusion of liability for
                incidental or consequential damages. accordingly, some of the
                above limitations of sections 17 and 18 may not apply to you.
              </p>
              <p>
                <span className="terms__service"> 6. ACCESS TO SERVICES </span>{" "}
                In order to use the App or the Services or access the Content,
                you must obtain access to the World Wide Web and pay any service
                fees associated with such access. In addition, you must provide
                all equipment necessary to make such a connection to the World
                Wide Web, including a computer and modem or other access
                devices. We are only providing suggestions to create teams, It
                all about your risk to use this in real fantasy sports
                applications.
              </p>
              <p>
                <span className="terms__service">
                  {" "}
                  7. BACKGROUND INFORMATION{" "}
                </span>{" "}
                In order to use the Services, you may be required to register
                with www.perfectlineup.in by providing certain information about
                yourself, including your name and email address. In
                consideration of your use of the Services, you agree to provide
                true, accurate, current and complete information about yourself
                as requested in connection with the provision of and/or
                registration for any of the Services, subject, of course, to all
                applicable laws. If you provide any information that is untrue,
                inaccurate, not current, or incomplete, or MyPredict11 has
                reasonable grounds to suspect that such information is untrue,
                inaccurate, not current, or incomplete, MyPredict11 has the
                right to suspend or terminate your password and/or refuse any
                and all current or future use of the Services.
              </p>
              <p>
                <span className="terms__service"> 8. ACCESS BY MINORS </span>{" "}
                You must be at least 15 years old to register on MyPredict11
                app. If you are under 15 years of age, you are not permitted to
                use the application or the Services or access the Content and
                App.
              </p>
              <p>
                <span className="terms__service"> 9. PRIVACY POLICY </span>
                9. PRIVACY POLICY All information collected about you during
                registration for use of the Services will be used in accordance
                with all applicable laws.
              </p>
              <p>
                <span className="terms__service">
                  {" "}
                  10. MEMBER PASSWORD AND SECURITY{" "}
                </span>
                You will receive a OTP upon completing the registration process
                for use of the Services. You are responsible for maintaining the
                confidentiality of the OTP and are fully responsible for all
                activities that occur under your password. MyPredict11 cannot
                and will not be liable for any loss or damage arising from your
                failure to comply with this Section 7.
              </p>
              <p>
                <span className="terms__service"> 11. MEMBER CONDUCT </span> You
                agree that in connection with your use of the Application, the
                Services, and the Content you will not:
              </p>
              <p>
                <span className="condition">A{")"}.</span> Upload, post, email
                or otherwise transmit any content that is unlawful, harmful,
                threatening, abusive, harassing, tortious, defamatory, vulgar,
                obscene, libellous, invasive of anyone's privacy, hateful, or
                racially, ethnically or otherwise objectionable;
              </p>
              <p>
                <span className="condition">B{")"}.</span> Harm or attempt to
                harm minors in any way;
              </p>
              <p>
                <span className="condition">C{")"}.</span> Impersonate any
                person or entity or falsely state or otherwise misrepresent your
                affiliation with a person or entity;{" "}
              </p>
              <p>
                <span className="condition">D{")"}.</span> Upload, post, email
                or otherwise transmit any content that you do not have a right
                to transmit under any law or under contractual or fiduciary
                relationships (such as inside information, proprietary and
                confidential information learned or disclosed as part of
                employment relationships or under nondisclosure agreements);
              </p>
              <p>
                <span className="condition">E{")"}.</span> Upload, post, email
                or otherwise transmit any content that infringes any patent,
                trademark, trade secret, copyright or other intellectual
                property rights of any party;
              </p>
              <p>
                <span className="condition">F{")"}.</span> Upload, post, email
                or otherwise transmit any unsolicited or unauthorized
                advertising, promotional materials, "junk mail", "spam", "chain
                letters", "pyramid schemes", or any other form of solicitation;
              </p>
              <p>
                <span className="condition">G{")"}.</span> Upload, post, email
                or otherwise transmit any material that contains software
                viruses or any other computer code, files or programs designed
                to interrupt, destroy or limit the functionality of any computer
                software or hardware or telecommunications equipment;
              </p>
              <p>
                <span className="condition">H{")"}.</span> Interfere with or
                disrupt the Application, the Services, the Content or servers or
                networks connected to the Application, the Services or the
                Content, or disobey any requirements, procedures, policies or
                regulations of networks connected to the Application, the
                Services and/or the Content;
              </p>
              <p>
                <span className="condition">I{")"}.</span> Intentionally or
                unintentionally violate any applicable local, state, national or
                international law.
              </p>
              <p>
                <span className="condition">J{")"}.</span> Reproduce, republish,
                download, post, transmit, distribute, copy, publicly display or
                otherwise use any Content or any derivative works based on the
                Application, Services, Content or the Software, in whole or in
                part.
              </p>
              <p>
                <span className="terms__service"> 12. USER MESSAGES </span>
              </p>
              <p>
                <span className="condition">A{")"}.</span> With respect to any
                messages, information, data, graphics, photographs, images,
                creative ideas, concepts, know-how, techniques, suggestions or
                improvements or other content that you transmit, submit, post or
                upload to the Application and/or in connection with any of the
                Services (each, "Message"), you are, by transmitting or
                uploading such Message, granting MyPredict11 and a perpetual,
                royalty-free, sub-licensable, non-exclusive, worldwide and
                irrevocable right and license to use, reproduce, modify,
                publish, translate, prepare derivative works based upon, and
                distribute such Message for any purpose and in any form. This
                license is granted automatically and no payment of any kind will
                be due to you. At times, MyPredict11 may solicit Messages from
                users. Your provision of any such Messages in response to a
                solicitation by MyPredict11 shall be subject to a grant of a
                license to MyPredict11 as specified above.
              </p>
              <p>
                <span className="condition">B{")"}.</span> When any user posts
                any Message to the Website or any of the Services, MyPredict11
                shall have the right, but not the obligation, to review, edit or
                delete any Message for any reason, at MyPredict11’s sole
                discretion. It is MyPredict11’s policy to fully cooperate with
                law enforcement authorities and court orders which request or
                require MyPredict11 to disclose personal information or the
                postings of anyone posting Messages to the Application or any of
                the Services. Although MyPredict11 may monitor and/or review
                Messages from time to time, it is under no obligation to do so
                and does not assume any liability or responsibility with respect
                to any Messages including those that contain errors, defamatory
                content, pornography, profanity or inaccuracies.
              </p>
              <p>
                <span className="condition">C{")"}.</span>You acknowledge and
                agree that MyPredict11 may preserve Messages and may also
                disclose Messages if required to do so by law or in the good
                faith belief that such preservation or disclosure is reasonably
                necessary to: (a) comply with legal process; (b) enforce the
                TOS; (c) respond to claims that any Message violates the rights
                of third-parties; or (d) protect the rights, property, or
                personal safety of MyPredict11, its users and the public. You
                understand that the technical processing and transmission of the
                Services, including Messages, may involve (a) transmissions over
                various networks; and (b) changes to conform and adapt to
                technical requirements of connecting networks or devices.
              </p>
              <p>
                <span className="terms__service"> 13. VOTING </span>
                MyPredict11 may offer you the opportunity to vote in connection
                with certain events. By casting your vote, you are agreeing to
                abide by the TOS and any and all voting guidelines posted on the
                Application.
              </p>
              <p>
                <span className="terms__service"> 14. INDEMNITY </span>
                You agree to indemnify, defend and hold harmless MyPredict11,
                the directors, governors, owners, other officials, partners,
                partnerships, principals, employees, affiliates and other
                related entities, servants, agents, representatives, successors
                and assigns from and against any claim or demand, including
                reasonable attorneys' fees, arising out of Messages you post to,
                submit or transmit through the Website or the Services, your use
                of the Application or the Services, your connection to the
                Website or the Services, your violation of the TOS, or your
                violation of any rights of another.
              </p>
              <p>
                <span className="terms__service">
                  {" "}
                  15. MODIFICATIONS TO WEBSITE, INCLUDING THE SERVICES{" "}
                </span>
                MyPredict11 reserves the right at any time and from time to time
                to modify or discontinue, temporarily or permanently, the
                Website or Services (or any part thereof) with or without
                notice.
              </p>
              <p>
                <span className="terms__service"> 16. TERMINATION </span>
                You agree that MyPredict11, in its sole discretion, may
                terminate your account or use of the Application and/or the
                Services, and remove and discard any Message within the
                Services, for any reason, including, without limitation, for
                lack of use or if MyPredict11 believe that you have violated or
                acted inconsistently with the letter or spirit of the TOS.
                MyPredict11 may also in its sole discretion and at any time
                discontinue providing the Website or the Services, or any part
                thereof, with or without notice. You agree that any termination
                of your access to the Services under any provision of this TOS
                may be effected without prior notice, and acknowledge and agree
                that MyPredict11 may immediately deactivate or delete your
                password and all related information and/or files corresponding
                to your password and/or bar any further access to such files or
                the Services.
              </p>
              <p>
                <span className="terms__service">
                  {" "}
                  17. MYPREDICT11 ADVERTISERS AND VENDORS{" "}
                </span>
                Your correspondence or business dealings with, or participation
                in promotions of, advertisers and vendors found on or through
                the Services or the Application, including payment and delivery
                of related goods or services, and any other terms, conditions,
                warranties or representations associated with such dealings, are
                solely between you and such advertiser and/or vendor. You agree
                that MyPredict11 shall not be responsible or liable for any loss
                or damage of any sort incurred as the result of any such
                dealings or as the result of the presence of such advertisers or
                vendors on the Application or in connection with the Services.
                ALL OF YOUR BUSINESS DEALINGS WITH VENDORS AND ADVERTISERS
                APPEARING ON THE APPLICATION OR IN CONNECTION WITH THE SERVICES
                SHALL BE AT YOUR SOLE RISK.
              </p>
              <p>
                <span className="terms__service">18. LINKING </span>
                The Application and/or Services may provide, or third parties
                may provide, links to other World Wide Web sites or resources.
                Because MyPredict11 have no control over such sites and
                resources, you acknowledge and agree that MyPredict11 are not
                responsible for the availability of such external sites or
                resources, and do not endorse and are not responsible or liable
                for any content, advertising, products, or other materials on or
                available from such sites or resources. You further acknowledge
                and agree that MyPredict11 shall not be responsible or liable,
                directly or indirectly, for any damage or loss caused or alleged
                to be caused by or in connection with the use of or reliance on
                any such content, goods or services available on or through any
                such site or resource.
              </p>
              <p>
                <span className="terms__service">19. PROPRIETARY RIGHTS </span>
                Services and Content are the property of MyPredict11 and are
                licensed to MyPredict11 and may not be reproduced without the
                prior written consent of MyPredict11. You further acknowledge
                and agree that (i) any necessary software used in connection
                with the Application, the Services, and the Content (the
                "Software") contains proprietary and confidential information
                that is protected by applicable intellectual property and other
                laws and are either owned by or licensed to MyPredict11; and
                (ii) content contained in sponsor advertisements or information
                presented to you through the Application, the Services, and the
                Content or advertisers is protected by copyrights, trademarks,
                service marks, patents or other proprietary rights and laws.
                EXCEPT AS EXPRESSLY AUTHORIZED BY MyPredict11, OR ADVERTISERS,
                YOU AGREE NOT TO REPRODUCE, REPUBLISH, UPLOAD POST, TRANSMIT,
                DISTRIBUTE, COPY, PUBLICLY DISPLAY OR OTHERWISE USE ANY CONTENT
                OR ANY DERIVATIVE WORKS BASED ON THE APPLICATION, SERVICES,
                CONTENT OR THE SOFTWARE, IN WHOLE OR IN PART. You agree not to
                modify the Software in any manner or form, or to use modified
                versions of the Software, including (without limitation) for the
                purpose of obtaining unauthorized access to the Services. You
                agree not to access the Services by any means other than through
                the interface that is provided by MyPredict11 for use in
                accessing the Services.
              </p>
              <p>
                <span className="terms__service"> 20. NOTICE </span>
                20. NOTICE Notices to you may be made via either email or
                regular mail. The Application may also provide notices of
                changes to the TOS or other matters by displaying notices or
                links to notices to you generally on the Services.
              </p>
              <p>
                <span className="terms__service">
                  {" "}
                  21. LINKING TO THE APPLICATION{" "}
                </span>
                No link to the Application may be "framed" to the extent such
                frame contains any sponsorship, advertising, or other commercial
                text or graphics. All links to the Application must be to the
                MyPredict11.
              </p>
              <p>
                <span className="terms__service">
                  {" "}
                  22. Payments – Terms and Conditions{" "}
                </span>
              </p>
              <p>
                <span className="condition">A{")"}.</span>There is no payment
                require to use of the application, we have point systems, so
                user gets amount based on points they earn. If you are get
                points by cheat code or any fraud, you will not get any amount
                from us and you will be deactivated by us without prior of
                notice.
              </p>
              <p>
                <span className="condition">B{")"}.</span> We are asking user’s
                for bank account & PAN information for redeem points and get
                amount in user bank account. If we found any illegal activities,
                will deactivate account without prior notice. User redeem cash
                only when they have minimum amount is more than 100.
              </p>
              <p>
                <span className="terms__service"> 23. POINT SYSTEM </span>
                We are calculating points based on our data and algorithm, So It
                might be vary from other apps. If we got any issues in it we are
                authorized to change in it. Data may vary from other platform,
                If we found any issues, we will resolved it, so no one is
                register complaints against us.
              </p>
              <p>
                <span className="terms__service"> 24. GENERAL PROVISIONS </span>
                The TOS constitute the entire agreement between you and
                MyPredict11 and governs your use of the Application, the
                Services, and access to the Content, superseding any prior
                agreements between you and MyPredict11. You also may be subject
                to additional terms and conditions that may apply when you use
                affiliate services, third-party content or third-party software.
                The TOS and the relationship between you and MyPredict11 shall
                be governed by the laws of Ireland without regard to its
                conflict of law provisions. You and MyPredict11 agree to submit
                to the personal and exclusive jurisdiction of the courts located
                within the country of Ireland. The failure of MyPredict11 to
                exercise or enforce any right or provision of the TOS shall not
                constitute a waiver of such right or provision. If any provision
                of the TOS is found by a court of competent jurisdiction to be
                invalid, the parties nevertheless agree that the court should
                endeavour to give effect to the parties' intentions as reflected
                in the provision, and the other provisions of the TOS remain in
                full force and effect. You agree that regardless of any statute
                or law to the contrary, any claim or cause of action arising out
                of or related to use of the Services or the TOS must be filed
                within one (1) year after such claim or cause of action arose or
                be forever barred. The section titles in the TOS are for
                convenience only and have no legal or contractual effect.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermsConditions;
