import React from "react";
import "../css/terms.css";
import Footer from "./Footer";
const PrivacyPolicy = () => {
  return (
    <>
      <div style={{ backgroundColor: "#2b0d88" }}>
        <div className="container">
          <div className="heading">Privacy Policy</div>
        </div>
      </div>
      <div className="container">
        <div className="secondSection">
          <div className="terms">
            <p>
              <span className="terms__service"> Meaningful Note</span>{" "}
              MyPredict11 is an analytic tool for cricket game that aims to
              enhance the knowledge of favorite cricketer of our users. It
              provides detailed analysis of cricketers that are optimized based
              on their past performances. We are purely data-driven solutions
              that provide different analytics tools and data/suggestions to our
              users.
            </p>
            <p>
              <span className="terms__service"> PRIVACY POLICY-</span> This
              privacy notice discloses the privacy practices for
              www.mypredict11.com. This privacy notice applies solely to
              information collected by this website. It will notify you of the
              following: What personally identifiable information is collected
              from you through the application, how it is used and with whom it
              may be shared. What choices are available to you regarding the use
              of your data? The security procedures are in place to protect
              against the misuse of your information. How you can correct any
              inaccuracies in the information. Information Collection, Use, and
              Sharing We are the sole owners of the information collected on
              this site. We only have access to/collect information that you
              voluntarily give us via email or other direct contacts from you.
              We will not sell or rent this information to anyone. We will use
              your information to respond to you, regarding the reason you
              contacted us. We will not share your information with any third
              party outside of our organization, other than as necessary to
              fulfil your request, e.g. to ship an order. Unless you ask us not
              to, we may contact you via email in the future to tell you about
              specials, new products or services, or changes to this privacy
              policy. We request information from you on our subscription form.
              To subscribe to MyPredict11, you must provide contact information
              (eg. name) and financial information (like card number, expiration
              date). This information is used for billing purposes and to
              activate your subscription. If we have trouble processing an
              order, we'll use this information to contact you.
            </p>
            <p>
              <span className="terms__service"> Registration</span> In order to
              use this website/application, a user must first complete the
              registration. During registration, a user is required to give
              certain information (such as name and phone number). This
              information is used to contact you about the products/services on
              our application in which you have expressed interest. At your
              option, you may also provide demographic information (such as
              gender or age) about yourself, but it is not required. Your Access
              to and Control Over Information You may opt-out of any future
              contacts from us at any time. You can do the following at any time
              by contacting us via the email address or phone number given on
              our website: See what data we have about you if any.
              Change/correct any data we have about you. Have us delete any data
              we have about you. Express any concern you have about our use of
              your data. See what data we have about you, if any. Change/correct
              any data we have about you.
            </p>
            <p>
              <span className="terms__service"> Sharing</span> We share
              aggregated demographic information with our partners and
              advertisers. This is not linked to any personal information that
              can identify any individual person.
            </p>
            <p>
              <span className="terms__service"> Surveys & Contests</span> From
              time to time our site requests information via surveys or
              contests. Participation in these surveys or contests is completely
              voluntary and you may choose whether or not to participate and
              therefore disclose this information. Information requested may
              include contact information (such as name and shipping address),
              and demographic information (such as zip code, age level). Contact
              information will be used to notify the winners and award prizes.
              Survey information will be used for purposes of monitoring or
              improving the use and satisfaction of this site.
            </p>
            <p>
              <span className="terms__service"> Links</span> This website
              contains links to other sites. Please be aware that we are not
              responsible for the content or privacy practices of such other
              sites. We encourage our users to be aware when they leave our site
              and to read the privacy statements of any other site that collects
              personally identifiable information.
            </p>
            <p>
              <span className="terms__service">Security</span> We take
              precautions to protect your information. When you submit sensitive
              information via the website, your information is protected both
              online and offline. Wherever we collect sensitive information
              (such as credit card data), that information is encrypted and
              transmitted to us in a secure way. You can verify this by looking
              for a lock icon in the address bar and looking for "HTTPS" at the
              beginning of the address of the Web page.
            </p>
            <p>
              While we use encryption to protect sensitive information
              transmitted online, we also protect your information offline. Only
              employees who need the information to perform a specific job (for
              example, billing or customer service) are granted access to
              personally identifiable information. The computers/servers in
              which we store personally identifiable information are kept in a
              secure environment.
            </p>
            <p>
              If you feel that we are not abiding by this privacy policy, you
              should contact us immediately via mypredict11.2022@gmail.com
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
