import styled from "@emotion/styled";
import { Avatar, Badge } from "@mui/material";
import React from "react";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid #221a62`,
}));

const FantasticPlayersView = ({ match }) => {
  const HighestPoints = match?.fantasyPoints?.reduce(
    (HighestPoints, fantasyPoint) => {
      return fantasyPoint.points > HighestPoints
        ? (HighestPoints = fantasyPoint.points)
        : HighestPoints;
    },
    0
  );
  return (
    <div className="trending_players_fantastic_top_container">
      {match?.fantasyPoints?.map((fantasyPoint, i) => (
        <div
          className="trending_players_fantastic_top"
          key={fantasyPoint.playerId}
        >
          <Avatar src={fantasyPoint.imageUrl} />
          <div>
            <p>{fantasyPoint.displayName}</p>
            <div
              className="progress"
              role="progressbar"
              aria-label="Example with label"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ position: "relative" }}
            >
              <div
                className="progress-bar"
                style={{
                  width: `${Math.floor(
                    (fantasyPoint.points / HighestPoints) * 100
                  )}%`,
                }}
              ></div>
              <span
                style={{
                  position: "absolute",
                  right: "5px",
                  font: "10px",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                  height: "inherit",
                }}
              >
                {((fantasyPoint.points / HighestPoints) * 100).toFixed(2)}%
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
const PitchReportView = ({ match }) => {
  return (
    <div className="trending_predictors_pitch_report">
      <div style={{ flex: ".4" }}>
        <p>Wins Batting first</p>
        <div
          className="progress"
          role="progressbar"
          aria-label="Example with label"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ position: "relative" }}
        >
          <div
            className="progress-bar"
            style={{ width: `${match?.pitchReport?.winsBattingFirst}%` }}
          ></div>
          <span
            style={{
              position: "absolute",
              right: "5px",
              font: "10px",
              color: "black",
              display: "flex",
              alignItems: "center",
              height: "inherit",
            }}
          >
            {match?.pitchReport?.winsBattingFirst?.toFixed(2)}%
          </span>
        </div>
      </div>
      <div style={{ flex: ".4" }}>
        <p>Wins Bowling first</p>
        <div
          className="progress"
          role="progressbar"
          aria-label="Example with label"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ position: "relative" }}
        >
          <div
            className="progress-bar"
            style={{ width: `${match?.pitchReport?.winsBowlingFirst}%` }}
          ></div>
          <span
            style={{
              position: "absolute",
              right: "5px",
              font: "10px",
              color: "black",
              display: "flex",
              alignItems: "center",
              height: "inherit",
            }}
          >
            {match?.pitchReport?.winsBowlingFirst?.toFixed(2)}%
          </span>
        </div>
      </div>
    </div>
  );
};
const MostSelectedCapAndViceCap = ({ match }) => {
  const totalPredictions = match.totalPredictors;
  return (
    <div className="trending_players_fantastic_top_container">
      <div
        className="trending_players_fantastic_top"
        style={{
          justifyContent: "flex-start",
        }}
      >
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <SmallAvatar
              style={{ fontSize: "10px", color: "black" }}
              alt="Captain"
              src=""
            >
              c
            </SmallAvatar>
          }
        >
          <Avatar
            src={
              match?.mostSelectedCaptainAndViceCaptain?.captain?.player
                ?.imageURL
            }
          />
        </Badge>

        <div style={{ marginLeft: "20px", flex: ".8" }}>
          <p>
            {
              match?.mostSelectedCaptainAndViceCaptain?.captain?.player
                ?.playerFullName
            }
          </p>
          <div
            className="progress"
            role="progressbar"
            aria-label="Example with label"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ position: "relative" }}
          >
            <div
              className="progress-bar"
              style={{
                width: `${
                  (match?.mostSelectedCaptainAndViceCaptain?.captain
                    ?.captainBy /
                    totalPredictions) *
                  100
                }%`,
              }}
            ></div>
            <span
              style={{
                position: "absolute",
                right: "5px",
                font: "10px",
                color: "black",
                display: "flex",
                alignItems: "center",
                height: "inherit",
              }}
            >
              {(
                (match?.mostSelectedCaptainAndViceCaptain?.captain?.captainBy /
                  totalPredictions) *
                100
              ).toFixed(2)}
              %
            </span>
          </div>
        </div>
      </div>
      <div
        className="trending_players_fantastic_top"
        style={{
          justifyContent: "flex-start",
        }}
      >
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <SmallAvatar
              style={{ fontSize: "10px", color: "black" }}
              alt="Captain"
              src=""
            >
              vc
            </SmallAvatar>
          }
        >
          <Avatar
            src={
              match?.mostSelectedCaptainAndViceCaptain?.viceCaptain?.player
                ?.imageUrl
            }
          />
        </Badge>
        <div style={{ marginLeft: "20px", flex: ".8" }}>
          <p>
            {
              match?.mostSelectedCaptainAndViceCaptain?.viceCaptain?.player
                ?.playerFullName
            }
          </p>
          <div
            className="progress"
            role="progressbar"
            aria-label="Example with label"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ position: "relative" }}
          >
            <div
              className="progress-bar"
              style={{
                width: `${
                  (match?.mostSelectedCaptainAndViceCaptain?.viceCaptain
                    ?.viceCaptainBy /
                    totalPredictions) *
                  100
                }%`,
              }}
            ></div>
            <span
              style={{
                position: "absolute",
                right: "5px",
                font: "10px",
                color: "black",
                display: "flex",
                alignItems: "center",
                height: "inherit",
              }}
            >
              {(
                (match?.mostSelectedCaptainAndViceCaptain?.viceCaptain
                  ?.viceCaptainBy /
                  totalPredictions) *
                100
              ).toFixed(2)}
              %
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
const MostSelected = ({ match }) => {
  const totalPredictions = match.totalPredictors;
  return (
    <div className="trending_players_fantastic_top_container">
      {match?.mostSelected?.map((player, index) => {
        return (
          <div key={index} className="trending_players_fantastic_top">
            <Avatar src={player.player.imageURL} />
            <div>
              <p>{player.player.playerFullName}</p>
              <div
                className="progress"
                role="progressbar"
                aria-label="Example with label"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ position: "relative" }}
              >
                <div
                  className="progress-bar"
                  style={{
                    width: `${(player.selectedBy / totalPredictions) * 100}%`,
                  }}
                ></div>
                <span
                  style={{
                    position: "absolute",
                    right: "5px",
                    font: "10px",
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                    height: "inherit",
                  }}
                >
                  {((player.selectedBy / totalPredictions) * 100).toFixed(2)}%
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Match = ({ match, heading, View }) => {
  return (
    <div className="match">
      <div className="trending_players_title">
        <p>{heading}</p>
      </div>
      <div className="match_header">
        <p style={{ color: "white", margin: 0 }}>{match.matchStartDateTime}</p>
        <div
          style={{
            border: "1px solid #ffd113",
            padding: "5px 10px",
            borderRadius: "12px",
            color: "white",
          }}
        >
          &nbsp; {match.totalPredictors} Predictions
        </div>
      </div>
      <div className="match_teams">
        <div>
          <Avatar style={{ marginRight: "5px" }} src={match.team1FlagURL} />
          {match.team1DisplayName}
        </div>
        <p style={{ color: "#ffd113" }}>VS</p>
        <div>
          {match.team2DisplayName}
          <Avatar src={match.team2FlagURL} style={{ marginLeft: "5px" }} />
        </div>
      </div>
      <View match={match} />
    </div>
  );
};

export default Match;
export {
  FantasticPlayersView,
  PitchReportView,
  MostSelectedCapAndViceCap,
  MostSelected,
};
