import "./App.css";
import { Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import TermsConditions from "./components/TermsConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Contact from "./components/Contact";
import About from "./components/About";
import NotFound from "./components/NotFound";
import DeleteAccount from "./components/DeleteAccount";

function App() {
  return (
    <Switch>
      <Route
        exact
        path="/delete-account"
        children={
          <>
            <DeleteAccount />
          </>
        }
      />
      <Route
        exact
        path="/home"
        children={
          <>
            <Navbar />
            <TermsConditions />
          </>
        }
      />
      <Route
        exact
        path="/terms-conditions"
        children={
          <>
            <Navbar />
            <TermsConditions />
          </>
        }
      />
      <Route
        exact
        path="/privacy-policy"
        children={
          <>
            <Navbar />
            <PrivacyPolicy />
          </>
        }
      />
      <Route
        exact
        path="/contact"
        children={
          <>
            <Navbar />
            <Contact />
          </>
        }
      />
      <Route
        exact
        path="/about"
        children={
          <>
            <Navbar />
            <About />
          </>
        }
      />
      <Route
        exact
        path="/"
        children={
          <>
            <Navbar />
            <Home />
          </>
        }
      />
      <Route component={NotFound} />
    </Switch>
  );
}

export default App;
