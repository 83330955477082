import { Avatar } from "@mui/material";
import React from "react";
import "../css/contact.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Footer from "./Footer";
import { SERVER_URL } from "../constants";

const Contact = () => {
  const [form, setForm] = React.useState({
    name: "",
    email: "",
    message: "",
    phone: "",
    subject: "",
  });
  const history = useHistory();

  const handleChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const submitForm = async (e) => {
    try {
      e.preventDefault();
      await axios.post(`${SERVER_URL}/v1/system/feedback`, {
        email: form.email,
        message: form.message,
      });
      history.push("/");
    } catch {
      history.push("/");
    }
  };
  return (
    <>
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "200px",
          marginBottom: "50px",
          gridGap: "30px",
        }}
      >
        <div
          className="crd"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "0",
            height: "inherit",
            justifyContent: "space-around",
          }}
        >
          <div
            className="cardBody contact_card_hover"
            style={{
              backgroundColor: "rgb(10, 0, 100)",
              color: "white",
              padding: "30px 20px",
              borderRadius: "12px",
            }}
          >
            <div
              className="con"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div style={{ flex: ".2" }}>
                <img
                  src="../image2_react/phoneIcon.png"
                  alt=""
                  style={{ width: "50px", height: "50px", margin: "auto" }}
                />
              </div>
              <div style={{ flex: ".8" }}>
                <p style={{ color: "white", margin: "0" }}>Contact</p>
                <p style={{ color: "white", margin: "0" }}>+91 9909595299</p>
                <p style={{ color: "white", margin: "0" }}>
                  inventionhill@gmail.com
                </p>
              </div>
            </div>
          </div>
          <div
            className="cardBody contact_card_hover"
            style={{
              backgroundColor: "rgb(10, 0, 100)",
              color: "white",
              padding: "30px 20px",
              borderRadius: "12px",
            }}
          >
            <div
              className="con"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div style={{ flex: ".2" }}>
                <img
                  src="../image2_react/address-icon-15.png"
                  alt=""
                  style={{ width: "50px", height: "50px", margin: "auto" }}
                />
              </div>
              <div style={{ flex: ".8" }}>
                <p
                  style={{
                    color: "white",
                    margin: "0",
                    wordWrap: "break-word",
                  }}
                >
                  Address B-1203, Ganesh Glory 11, Jagatpur road, Sarkhej -
                  Gandhinagar Hwy Ahmedabad, Gujarat 382481
                </p>
              </div>
            </div>
          </div>
          <div
            className="cardBody contact_card_hover"
            style={{
              backgroundColor: "rgb(10, 0, 100)",
              color: "white",
              padding: "30px 20px",
              borderRadius: "12px",
            }}
          >
            <div
              className="con"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridGap: "10px",
                }}
              >
                <div
                  style={{
                    color: "white",
                    margin: "0",
                    wordWrap: "break-word",
                  }}
                >
                  <a
                    href="https://www.instagram.com/mypredict11"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Avatar
                      src={`${
                        process.env.PUBLIC_URL + "/image_react/insta_logo.png"
                      } `}
                    />
                    <span style={{ marginLeft: "30px" }}>@mypredict11</span>
                  </a>
                </div>
                <div
                  style={{
                    color: "white",
                    margin: "0",
                    wordWrap: "break-word",
                  }}
                >
                  <a
                    href="https://www.facebook.com/profile.php?id=100086729386356"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Avatar
                      src={`${
                        process.env.PUBLIC_URL +
                        "/image_react/facebook_logo.png"
                      } `}
                    />
                    <span style={{ marginLeft: "30px" }}>@mypredict11</span>
                  </a>
                </div>
                <div
                  style={{
                    color: "white",
                    margin: "0",
                    wordWrap: "break-word",
                  }}
                >
                  <a
                    href="https://twitter.com/mypredict11/"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Avatar
                      src={`${
                        process.env.PUBLIC_URL +
                        "/image_react/twitter_logo.jpeg"
                      } `}
                    />
                    <span style={{ marginLeft: "30px" }}>@mypredict11</span>
                  </a>
                </div>
                <div
                  style={{
                    color: "white",
                    margin: "0",
                    wordWrap: "break-word",
                  }}
                >
                  <a
                    href="https://wa.me/9909595299"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      textDecoration: "none",
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Avatar
                      src={`${
                        process.env.PUBLIC_URL +
                        "/image_react/whatsapp_logo.png"
                      } `}
                    />
                    <span style={{ marginLeft: "30px" }}>9909595299</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="contactBox contact_card_hover"
          style={{
            backgroundColor: "rgb(10 0 100)",
            borderRadius: "12px",
            marginTop: "0",
            padding: "40px 20px 20px 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              className="head"
              style={{
                color: "#ffd113",
                fontSize: "20px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              Get in Touch
            </span>
            <span
              className="secondHead"
              style={{
                fontSize: "2.25rem",
                fontWeight: "600",
                color: "white",
                textAlign: "center",
              }}
            >
              Ready to Get Started
            </span>
          </div>
          <div>
            <form onSubmit={submitForm}>
              <div
                style={{
                  padding: "30px 0",
                  display: "flex",
                  flexDirection: "column",
                  gridGap: "16px",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <input
                    required
                    type="text"
                    name="name"
                    placeholder="Name"
                    style={{
                      borderRadius: "12px",
                      width: "345px",
                      padding: "10px 0px 10px 20px",
                    }}
                    onChange={handleChange}
                    value={form.name}
                    className="textArea"
                  />
                  <input
                    type="email"
                    onChange={handleChange}
                    value={form.email}
                    placeholder="Email"
                    name="email"
                    required
                    style={{
                      borderRadius: "12px",
                      width: "345px",
                      padding: "10px 0px 10px 20px",
                    }}
                    className="textArea"
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <input
                    type="tel"
                    pattern="[0-9]{10}"
                    onChange={handleChange}
                    value={form.phone}
                    required
                    name="phone"
                    placeholder="Phone"
                    style={{
                      borderRadius: "12px",
                      width: "345px",
                      padding: "10px 0px 10px 20px",
                    }}
                    className="textArea"
                  />
                  <input
                    type="text"
                    onChange={handleChange}
                    value={form.subject}
                    required
                    name="subject"
                    placeholder="Subject"
                    style={{
                      borderRadius: "12px",
                      width: "345px",
                      padding: "10px 0px 10px 20px",
                    }}
                    className="textArea"
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <textarea
                    id=""
                    required
                    name="message"
                    cols="68"
                    rows="5"
                    minLength={30}
                    onChange={handleChange}
                    value={form.message}
                    placeholder="Type Message"
                    style={{ borderRadius: "15px", padding: "14px", flex: "1" }}
                  ></textarea>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    style={{
                      borderRadius: "30px",
                      padding: "5px 30px 5px 30px",
                      backgroundColor: "#1c3ab6",
                      color: "white",
                      border: "none",
                    }}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
